import React from 'react';
import {
  BrowserRouter,
  withRouter
} from "react-router-dom";

import CacheBuster from './CacheBuster'
import App from './App'

const AppWithRouter = withRouter(App);

function AppContainer(props) {
  return (
    <CacheBuster>{
        ({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
          
        return (
            <BrowserRouter>
              <AppWithRouter />
            </BrowserRouter>
        );
      }
    }</CacheBuster>
  );
}

export default AppContainer;