import React, {useState} from 'react';
import { Link } from "react-router-dom";

import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Card from 'react-bootstrap/Card'

import ConfirmModal from './ConfirmModal';

function EntriesTableRows(props) {
    return (
        <tr key={props.entry.creationdate + "" + props.index}>
            <td>{props.entry.time}</td>
            <td>{props.entry.nutrition}cc</td>
            <td>{props.entry.urine ? "ja" : "nee"}</td>
            <td>{props.entry.stool ? "ja" : "nee"}</td>
            <td>{props.entry.vitamin ? "ja" : "nee"}</td>
            <td>
                {props.apiToken && (
                    <Button className="delete-button" 
                            onClick={(e) => {props.configModalShow(props.entry.creationdate)}} 
                            variant="outline-danger" 
                            size="sm">
                        <strong>X</strong>
                    </Button>
                )}
            </td>
        </tr>);
}

function Overview(props) {
    const [show, setShow] = useState(false);
    const [deleteEntry, setDeleteEntry] = useState("");

    const getAddUrlWithToken = () => "/" + props.apiToken + "/add";

    const configModalShow = (creationdate) => {
        setDeleteEntry(creationdate);
        setShow(true);
    }

    const confirmed = () => {
        props.deleteEntry(deleteEntry);
        handleClose();
    }

    const handleClose = () => {
        setShow(false);
        setDeleteEntry("");
    };

    const date_sort = (a, b) => {
        return new Date(b.excecutiondate).getTime() - new Date(a.excecutiondate).getTime();
    }

    const time_sort = (a, b) => {
        return a.time.replace(':', '') - b.time.replace(':', '');
    }

    const sumNutritionPerDay = (total, entry) => {
        return total + entry.nutrition;
    }

    const sortEntries = () => {
        let sortedEntries = props.entries.sort(date_sort);

        let groupedEntries = [];
        let dates = [...new Set(sortedEntries.map(entry => entry.date))];
        for (const date of dates) {
            let dateObject = new Date(date);
            let entriesByDate = sortedEntries.filter(entry => entry.date === date);
            let totalNutrition = entriesByDate.reduce(sumNutritionPerDay, 0);
            groupedEntries.push({ date: dateObject.toLocaleDateString(), entries: entriesByDate.sort(time_sort), totalNutrition: totalNutrition });
        }
        return groupedEntries;
    }

    return (
        <div className="overview" >
            {sortEntries().map((dates, index) => {
                return (
                    <div key={index}>
                        <div className="timeline-header">{dates.date}</div>
                        <Card style={{margin: '0 0 1em 0'}}>
                            <Card.Body>
                                <Table borderless responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>Tijd</th>
                                            <th>Voeding</th>
                                            <th>U</th>
                                            <th>O</th>
                                            <th>D</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dates.entries.map((entry, indexEntry) => {
                                            return (<EntriesTableRows key={indexEntry} entry={entry} index={indexEntry} configModalShow={configModalShow} apiToken={props.apiToken} />);
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Totaal:</td>
                                            <td>{dates.totalNutrition}cc</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Card.Body>
                        </Card>
                    </div>
                );
            })}

            <ConfirmModal show={show} handleClose={handleClose} confirmed={confirmed} />
            
            {props.apiToken && (
                <Navbar className="justify-content-end" expand="lg" variant="light" bg="light" fixed="bottom">
                    <Link to={getAddUrlWithToken}>
                        <Button variant="primary">Toevoegen</Button>
                    </Link>
                </Navbar>
            )}
        </div>
    );
}


export default Overview;