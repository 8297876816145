import React from 'react';
import { Link } from "react-router-dom";

import { Formik } from 'formik'
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'


const AddForm = (props) => {
    let today = new Date();

    const getCancelUrlWithToken = () => "/"+props.apiToken;

    const schema = Yup.object({
        date: Yup.date()
            .required(),
        time: Yup.string()
            .required(),
        nutrition: Yup.number()
            .min(10)
            .max(300)
            .required()
    });

    const initialValues = {
        date: today.toISOString().substring(0, 10),
        time: today.toTimeString().substring(0, 5),
        nutrition: '',
        urine: false,
        stool: false,
        vitamin: false
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {
                props.addEntry(values);
            }}
        >
        {({ errors, values, handleChange, handleSubmit }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="validationFormik01">
                    <Form.Label>Datum: </Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="dd-mm-yyyy"
                        name='date'
                        value={values.date}
                        onChange={handleChange}
                        isInvalid={!!errors.date} />

                    <Form.Control.Feedback type="invalid">
                        {errors.date}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationFormik02">
                    <Form.Label>Tijd: </Form.Label>
                    <Form.Control
                        type="time"
                        placeholder="hh:ss"
                        name='time'
                        value={values.time}
                        onChange={handleChange}
                        isInvalid={!!errors.time} />

                    <Form.Control.Feedback type="invalid">
                        {errors.time}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationFormik03">
                    <Form.Label>Voeding: </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="0"
                        name='nutrition'
                        value={values.nutrition}
                        onChange={handleChange}
                        isInvalid={!!errors.nutrition} />

                    <Form.Control.Feedback type="invalid">
                        {errors.nutrition}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationFormik04">
                    <Form.Check
                        type="switch"
                        name="urine"
                        label="Urine"
                        value={values.urine}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="validationFormik05">
                    <Form.Check
                        type="switch"
                        name="stool"
                        label="Ontlasting"
                        value={values.stool}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="validationFormik06">
                    <Form.Check
                        type="switch"
                        name="vitamin"
                        label="Vitamine D"
                        value={values.vitamin}
                        onChange={handleChange}
                    />
                </Form.Group>
                
                <Button variant="primary" type="submit">Opslaan</Button>
                <Link to={getCancelUrlWithToken}>
                    <Button variant="link">Cancel</Button>
                </Link>
            </Form>
        )}
        </Formik>
    );
}

export default AddForm;