import React, { useState, useEffect } from 'react';
import axios from 'axios'

import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'

import AddForm from './pages/AddForm'
import Overview from './pages/Overview'

const apiUrl = process.env.REACT_APP_API_URL + "entries?name=levi";

function App(props) {
  const matchApiTokenParam = useRouteMatch("/:token")

  const [entries, setEntries] = useState([]);
  const [apiToken, setApiToken] = useState('');

  const axiosClient = axios.create({
    baseURL: apiUrl,
    headers: {
      common: {
        "content-type": 'application/json',
        'x-api-key': apiToken
      }
    }
  });

  useEffect(() => {
    if(matchApiTokenParam){
      setApiToken(matchApiTokenParam.params.token);
    }
  }, [matchApiTokenParam]);

  useEffect(() => {
    axios.get(apiUrl)
      .then(response => {
        setEntries(response.data.entries);
      });
  }, []);

  const handleErrors = (errorResponse) => {
    let response = errorResponse.response;
    if(response.status === 403 || response.status === 401){
      alert('U heeft geen toegang!');
      return;
    }
    console.log(errorResponse);
  }

  const addEntry = (values) => {
    values.creationdate = new Date().toISOString();
    values.excecutiondate = new Date(values.date + 'T' + values.time).toISOString();

    axiosClient.put('', JSON.stringify(values))
      .then(() => {
        setEntries([...entries, values]);
        props.history.push('/' + apiToken);
      })
      .catch(error => handleErrors(error));
  }

  const deleteEntry = (creationdate) => {
    axiosClient.delete('', {
        "data": JSON.stringify({"creationdate": creationdate})
      })
      .then((resp) => {
        setEntries(entries.filter(e => e.creationdate !== creationdate));
      })
      .catch(error => handleErrors(error));
  }

  return (
    <Container>
      <Row>

        <Col md={4} xs={12}>
          <Card className="text-center">
            <Card.Body>
              <Image src="/img/apple-icon-180.png" roundedCircle />
              <Card.Title>Levi Visscher</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={8} xs={12}>
          <Switch>
            <Route path="/:token/add">
              <AddForm addEntry={addEntry} apiToken={apiToken} />
            </Route>

            <Route path="/">
              <Overview entries={entries} deleteEntry={deleteEntry} apiToken={apiToken} />
            </Route>
          </Switch>
        </Col>

      </Row>
    </Container>
  );
}

export default App;