import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function ConfirmModal(props) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Body>Weet u het zeker?</Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.handleClose}>
                    Nee
                </Button>
                <Button variant="danger" onClick={props.confirmed}>
                    Ja
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmModal;